import { useStaticQuery, graphql } from 'gatsby'

export const useAppSettings = () => {
  const { sanityAppSettings } = useStaticQuery(
    graphql`
      query appSettings {
        sanityAppSettings(_id: { eq: "Settings" }) {
          appName
          appLogo
          _rawFooterMenu(resolveReferences: { maxDepth: 10 })
          bgImage {
            asset {
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `
  )
  return sanityAppSettings
}
