import React from 'react'

import { useAppSettings } from '../hooks/useAppSettings'

import BackgroundImage from 'gatsby-background-image'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import TopBar from '../mui-components/TopBar/TopBar'
import BottomNavigation from '../mui-components/BottomNavigation/BottomNavigation'

import UpdateSnackbar from '../components/update-sw-snackbar'
import EpisodesBar from '../components/episodes-bar'
import FactsBar from '../components/facts-bar'
import AnalogyFinderTabs from '../components/analogy-finder-tabs'

// Import placeholder image, so it can be cached here and we can use it in image block as offline fallback
import placeholderImg from '../assets/images/image-offline.jpg'

const styles = makeStyles(theme => ({
  root: {
    // Fix for page transitions
    overflow: 'hidden'
  },
  bg: {
    backgroundAttachment: 'fixed',
    minHeight: '100vh'
  },
  bgHide: {
    '@media (max-width:768px)': {
      '&:before, &:after': {
        background: 'none !important'
      }
    }
  },
  container: {
    padding: theme.spacing(2),
    // Adjust padding only for smallest screens
    '@media (min-width:320px) and (max-width:360px)': {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  box: {
    paddingTop: '62px',
    paddingBottom: '56px'
  },
  hpBox: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    marginBottom: 0
  },
  hpBoxContainer: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    padding: '20px',
    '@media (min-width:320px) and (max-width:360px)': {
      paddingLeft: '12px',
      paddingRight: '12px'
    }
  }
}))

const Layout = props => {
  const classes = styles(props)
  const placeholderImgUrl = placeholderImg

  const { children, title, location } = props
  const isHomepage = location.pathname === '/'
  const { bgImage } = useAppSettings()

  let episodesBar = null
  let factsBar = null

  const containerClasses = [classes.container]
  if (isHomepage) {
    containerClasses.push(classes.hpBoxContainer)
  }

  const boxClasses = [classes.bg, classes.bgHide, classes.box]
  if (isHomepage) {
    boxClasses.push(classes.hpBox)
    boxClasses.splice(1, 1)
  }

  // Prepare Episodes bar when needed
  if (props.data && props.data.relatedEpisodes) {
    episodesBar = <EpisodesBar data={props.data.relatedEpisodes} location={location} />
  }

  // Prepare Facts bar when needed
  if (props.data && props.data.relatedFacts) {
    factsBar = <FactsBar data={props.data.relatedFacts} location={location} />
  }

  // Prepare Analogy facts tabs when needed
  if (props.analogyTabsData) {
    factsBar = <AnalogyFinderTabs data={props.analogyTabsData} location={location} />
  }

  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  // Also provide fallback for non-HP pages
  const backgroundFluidImageStackHomepage = [
    bgImage.asset.fluid,
    'linear-gradient(0deg, rgba(66, 154, 250, 0.45), rgba(66, 154, 250, 0.45))'
  ].reverse()

  const backgroundFluidImageStack = [
    bgImage.asset.fluid,
    'linear-gradient(0deg, rgba(247, 248, 251, 0.5), rgba(247, 248, 251, 0.5))'
  ].reverse()

  return (
    <div className={classes.root}>
      <TopBar
        title={title}
        color={isHomepage ? 'secondary' : 'primary'}
        showBack={!isHomepage}
        location={location}
      />

      {/* Also apply bottom margin in the height fixed BottomNavigation */}
      <BackgroundImage
        className={boxClasses.join(' ')}
        fadeIn="false"
        fluid={isHomepage ? backgroundFluidImageStackHomepage : backgroundFluidImageStack}
      >
        {/* Add Episodes bar only when needed, i.e. on Single Case/Single Episode */}
        {episodesBar}

        {/* Add Facts bar only when needed, i.e. on Single Facts */}
        {factsBar}

        <Container disableGutters className={containerClasses.join(' ')} maxWidth="md">
          {children}
        </Container>
      </BackgroundImage>

      <UpdateSnackbar />

      {isHomepage ? '' : <BottomNavigation location={location} component="footer" />}

      {/* Load placeholder image here, so it will be cached and work offline and we can use it block content image */}
      <img src={placeholderImg} style={{ display: 'none' }} />
    </div>
  )
}

export default Layout
